import { FC, Fragment, useState } from 'react';
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Avatar } from '@lobehub/ui';
import { Typography, Divider, Button, Spin } from 'antd';
import { styled } from '@mui/material/styles';
import { Box, List, ListItem, Stack } from '@mui/material';
import {
  X,
  Rocket,
  SearchCheck,
  CalendarClock,
  Inbox,
  BrainIcon,
  CheckCheck,
  Check,
  Brain,
} from 'lucide-react';
import { useNotifications } from './hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Notification } from 'src/types/notifications';
import { humanRedableDateString, timestampUnixToDate } from 'src/utils/date-locale';
import { APIListNoCount } from 'src/types/api';
import { useNavigate } from 'react-router';
import { paths } from 'src/paths';
import { useAuth } from 'src/hooks/use-auth';

const { Title, Text, Link } = Typography;
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  zIndex: 9999,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.grey[300]}`,
    minWidth: '400px',
  },
}));

interface NotificationsProps {
  isLoading: boolean;
  data: APIListNoCount<Notification> | undefined;
}
const Notifications: FC<NotificationsProps> = (props) => {
  const { isLoading, data } = props;

  if (isLoading) {
    return (
      <Box
        p={2}
        gap={1}
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Spin
          indicator={<LoadingOutlined spin />}
          size="default"
        />
        <Text style={{ color: 'gray' }}>Loading notifications</Text>
      </Box>
    );
  }

  if (!isLoading && data?.results?.length === 0) {
    return (
      <Box
        p={2}
        gap={1}
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Text
          type="secondary"
          style={{ textAlign: 'center' }}
        >{`You don't have notifications at the moment`}</Text>
        <Inbox style={{ color: 'gray' }} />
      </Box>
    );
  }

  return (
    <List>
      {data?.results?.map((notification, index) => {
        return (
          <Fragment key={`div-${notification.notification_id}`}>
            {index > 0 && <Divider />}
            <NotificationItem
              // key={`not-${notification.notification_id}`}
              notification={notification}
            />
          </Fragment>
        );
      })}
    </List>
  );
};

const AIAvatar = () => {
  const { isLoading, data } = useNotifications();
  return (
    <>
      <HtmlTooltip
        placement="bottom"
        title={
          <Notifications
            isLoading={isLoading}
            data={data}
          />
        }
      >
        <Avatar
          size={48}
          animation={true}
          avatar="/assets/icons/nova.png"
          shape="circle"
        />
      </HtmlTooltip>
    </>
  );
};

const NotificationItem = (props: { notification: Notification }) => {
  const { notification } = props;
  const navigate = useNavigate();
  const { user } = useAuth();
  const handleChatNavigation = () => {
    const path = user?.smb_id ? paths.business.chat : paths.workspace.chat;
    navigate(`${path}?chat_id=${notification.chat_id}`)
  }
  return (
    <ListItem sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
      <Box>
        <Avatar
          animation={false}
          avatar={notification.status === 'new' ? <Inbox /> : <CheckCheck />}
          background={notification.status === 'new' ? '#d4d4d4' : '#d4d4d4'}
          shape="circle"
        />
      </Box>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Title
            level={5}
            style={{ marginTop: 0 }}
          >
            {notification.title}
          </Title>
          <X
            size={20}
            style={{ cursor: 'pointer', color: 'GrayText' }}
          />
        </Stack>
        <Text type="secondary">{notification.text}</Text>
        <Text style={{ fontSize: '12px', color: 'gray', fontWeight: 'bold', marginTop: '5 px' }}>
          {timestampUnixToDate(notification.timestamp)}
        </Text>
        <Stack
          pt={1}
          direction="row"
          spacing={2}
          justifyContent="flex-end"
        >
          <Button
            icon={<Brain size={18} />}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
            type="dashed"
            onClick={handleChatNavigation}
          >
            Chat with Nova
          </Button>
        </Stack>
      </Stack>
    </ListItem>
  );
};

export default AIAvatar;
