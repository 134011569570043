import type { DEPRECATED_DO_NOT_USE_User, User } from 'src/types/user';
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from 'src/utils/jwt';
import { wait } from 'src/utils/wait';

import { users } from './data';
import axios, { type AxiosInstance } from 'axios';
import {
  VITE_API_LEVELPLANE,
  VITE_BASE_UI_URL,
  VITE_COGNITO_API,
  VITE_COGNITO_CLIENT_ID,
  VITE_COGNITO_GRANT_TYPE,
  VITE_COGNITO_REDIRECT_URL,
} from 'src/config';
import {
  CognitoIdentityProviderClient,
  ConfirmForgotPasswordCommand,
  ForgotPasswordCommand,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
} from '@aws-sdk/client-cognito-identity-provider';

const STORAGE_KEY = 'users';

const getPersistedUsers = (): DEPRECATED_DO_NOT_USE_User[] => {
  try {
    const data = sessionStorage.getItem(STORAGE_KEY);

    if (!data) {
      return [];
    }

    return JSON.parse(data) as DEPRECATED_DO_NOT_USE_User[];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const persistUser = (user: DEPRECATED_DO_NOT_USE_User): void => {
  try {
    const users = getPersistedUsers();
    const data = JSON.stringify([...users, user]);
    sessionStorage.setItem(STORAGE_KEY, data);
  } catch (err) {
    console.error(err);
  }
};

type SignInRequest = {
  email: string;
  password: string;
};

type SignInResponse = Promise<{
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  accessToken: string;
}>;

type SignUpRequest = {
  token: string;
  refresh_token: string;
};

type SignUpResponse = Promise<{
  accessToken: string;
}>;

type ChangePasswordRequest = {
  email: string;
  password: string;
  session: string;
};

type ChangePasswordRespone = Promise<{
  accessToken: string;
}>;

type MeRequest = {
  accessToken: string;
};

type MeResponse = Promise<User>;

class AuthApi {
  private axiosCognito: AxiosInstance;
  private axiosRegister: AxiosInstance;

  constructor() {
    this.axiosCognito = axios.create({
      baseURL: `${VITE_COGNITO_API}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    this.axiosRegister = axios.create({
      baseURL: `${VITE_API_LEVELPLANE}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async signInWithCognitoCode(code: string): SignInResponse {
    try {
      const params = new URLSearchParams();
      params.append('grant_type', VITE_COGNITO_GRANT_TYPE);
      params.append('client_id', VITE_COGNITO_CLIENT_ID);
      params.append('redirect_uri', VITE_COGNITO_REDIRECT_URL);
      params.append('code', code);

      const { data } = await this.axiosCognito.post('/oauth2/token', params);
      return data;
    } catch (error) {
      // Handle error
      console.log(error);
      throw error;
    }
  }

  async googleLogin(redirectUri: string, signup_key: string, org_name: string) {
    try {
      const client_id = VITE_COGNITO_CLIENT_ID;
      const response_type = 'code';
      const scope = encodeURIComponent('email openid phone profile');
      const redirect_uri = encodeURIComponent(VITE_BASE_UI_URL + '/dashboard/');
      const identity_provider = 'Google';
      const { data } = await this.axiosCognito.get(
        `/oauth2/authorize?identity_provider=${identity_provider}&redirect_uri=${redirect_uri}&response_type=${response_type}&client_id=${client_id}&scope=${scope}`
      );
      console.log(JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
  }

  async signIn(request: SignInRequest): SignInResponse {
    const { email, password } = request;

    await wait(500);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const cognitoIdentityProviderClient = new CognitoIdentityProviderClient({
        region: 'us-east-1',
      });
      if (email && password) {
        try {
          const response = await cognitoIdentityProviderClient.send(
            new InitiateAuthCommand({
              AuthFlow: 'USER_PASSWORD_AUTH',
              ClientId: VITE_COGNITO_CLIENT_ID,
              AuthParameters: {
                USERNAME: email,
                PASSWORD: password,
              },
            })
          );
          if (response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
            reject({
              challengeName: 'NEW_PASSWORD_REQUIRED',
              session: response.Session,
              email: email,
              message: 'NEW_PASSWORD_REQUIRED',
            });
          } else if (response.AuthenticationResult) {
            resolve({
              accessToken: response.AuthenticationResult.IdToken || '',
              access_token: '',
              expires_in: 36000,
              id_token: '',
              refresh_token: '',
              token_type: '',
            });
          } else {
            console.error('Unexpected condition during signIn', response);
            reject(new Error('Unexpected error when logging in, please try again later'));
          }
        } catch (error) {
          console.error('Authentication failed:', error);
          reject(new Error('Authentication Failed'));
        }
      } else {
        reject(new Error('Invalid email or password'));
      }
    });
  }

  async confirmSignUp(request: SignUpRequest): SignUpResponse {
    const { token, refresh_token } = request;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await this.axiosRegister.post(
          '/v1/organizations/',
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
      try {
        const data = new URLSearchParams();
        data.append('grant_type', 'refresh_token');
        data.append('client_id', VITE_COGNITO_CLIENT_ID);
        data.append('refresh_token', refresh_token);
        const response = await this.axiosCognito.post('/oauth2/token', data);
        const accessToken = response.data.id_token;
        resolve({ accessToken });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async smbSignupEmail(email: string, password: string, smb_id: string, org_id?: string) {
    // eslint-disable-next-line no-async-promise-executor
    try {
      if (org_id) {
        const response = await this.axiosRegister.post('/v1/signup', {
          email,
          password,
          user_type: 'smb',
          smb_name: smb_id,
          org_id,
        });
        return response;
      } else {
        const response = await this.axiosRegister.post('/v1/signup', {
          email,
          password,
          user_type: 'smb',
          smb_name: smb_id,
        });
        return response;
      }
      
      // const accessToken = response.data.cognito_tokens.IdToken;
    } catch (err) {
      console.error(err);
    }
  }

  async smbSignupEmailCompanyCode(email: string, password: string, org_signup_key: string, companyCode?: string, smb_name?: string, smb_id?: string) {
    // eslint-disable-next-line no-async-promise-executor
    try {
      let response
      if (smb_name && companyCode) {
        response = await this.axiosRegister.post('/v1/signup', {
          email,
          password,
          user_type: 'smb',
          smb_name,
          org_signup_key,
          smb_company_code: companyCode,
        });
      } else {
        response = await this.axiosRegister.post('/v1/signup', {
          email,
          password,
          user_type: 'smb',
          org_signup_key,
          smb_id,
        });
      }
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  async smbSignupConfirmToken(token: string): SignUpResponse {
    return new Promise((resolve, reject) => {
      try {
        const accessToken = token;
        resolve({ accessToken });
      } catch (err) {
        console.error(err);
        reject(new Error('Internal Server Error'));
      }
    });
  }

  async updateChatUserId(user_id: string, chat_id: string, token: string) {
    try {
      const response = await this.axiosRegister.patch(
        `/v1/omni-chats/${chat_id}`,
        {
          user_id: user_id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (err) {
      console.error(err);
    }
  }

  async smbSignupGoogle(idp_code: string, smb_id: string): SignUpResponse {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.axiosRegister.post('/v1/signup', {
          idp_code,
          user_type: 'smb',
          smb_id,
        });
        const accessToken = response.data.id_token;
        resolve({ accessToken });
      } catch (err) {
        console.error(err);
        reject(new Error('Internal Server Error'));
      }
    });
  }

  async smbSignupGoogleCode(idp_code: string, orgSignupKey: string, smb_id?: string, smb_name?: string, companyCode?: string) {
    // eslint-disable-next-line no-async-promise-executor
    try {
      let response;
      if (smb_name) {
        response = await this.axiosRegister.post('/v1/signup', {
          idp_code,
          smb_company_code: companyCode,
          org_signup_key: orgSignupKey,
          smb_name,
          user_type: "smb",
        });
      }
      else {
        response = await this.axiosRegister.post('/v1/signup', {
          idp_code,
          smb_id,
          org_signup_key: orgSignupKey,
          user_type: "smb",
        });
      }
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async smbESTokenPush(refresh_token: string): SignUpResponse {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const data = new URLSearchParams();
        data.append('grant_type', 'refresh_token');
        data.append('client_id', VITE_COGNITO_CLIENT_ID);
        data.append('refresh_token', refresh_token);
        const response = await this.axiosCognito.post('/oauth2/token', data);
        const accessToken = response.data.id_token;
        resolve({ accessToken });
      } catch (err) {
        console.error(err);
        reject(new Error('Internal Server Error'));
      }
    });
  }

  async smbSignupMenu(email: string, org_id: string, user_type: string, password: string) {
    try {
      const { data } = await this.axiosRegister.post('/v1/signup', {
        email,
        user_type,
        password,
        org_id,
        preferred_language: 'es',
      });
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async smbSignupMenuGoogle(email: string, org_id: string, user_type: string, idp_code: string) {
    try {
      const response = await this.axiosRegister.post('/v1/signup', {
        email,
        user_type,
        org_id,
        preferred_language: 'es',
        idp_code,
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getSmbNames(org_id: string, companyName: string, token: string) {
    try {
      const { data } = await this.axiosRegister.get(
        `/v1/smbs/list?org_id=${org_id}&smart_name=${companyName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return data.results;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async signupExistingSmb(smb_id: string, user_id: string, token: string) {
    try {
      const response = await this.axiosRegister.patch(
        `/v1/profiles/${user_id}`,
        {
          smb_id: smb_id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async signupNewSmb(company_name: string, business_type: string, org_id: string, token: string) {
    try {
      const { data } = await this.axiosRegister.post(
        `/v1/smbs/`,
        {
          name: company_name,
          business_type,
          org_id,
          location: { address: 'Mexico' },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async verifyCompanyCode(companyCode: string) {
    try {
      const response = await this.axiosRegister.get(`/v1/smb_exists/?company_code=${companyCode}`);
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  async forgotPassword(username: string) {
    try {
      const cognitoIdentityProviderClient = new CognitoIdentityProviderClient({
        region: 'us-east-1',
      });
      if (username) {
        const input = {
          ClientId: VITE_COGNITO_CLIENT_ID,
          Username: username,
        };
        const command = new ForgotPasswordCommand(input);
        await cognitoIdentityProviderClient.send(command);
        console.log('Success!');
      } else {
        throw new Error('No username!');
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async confirmNewPassword(username: string, password: string, code: string) {
    try {
      const cognitoIdentityProviderClient = new CognitoIdentityProviderClient({
        region: 'us-east-1',
      });
      if (username) {
        const input = {
          ClientId: VITE_COGNITO_CLIENT_ID,
          Username: username,
          ConfirmationCode: code,
          Password: password,
        };
        const command = new ConfirmForgotPasswordCommand(input);
        await cognitoIdentityProviderClient.send(command);
      } else {
        throw new Error('No username!');
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async changePassword(request: ChangePasswordRequest): SignUpResponse {
    const { email, password, session } = request;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const cognitoIdentityProviderClient = new CognitoIdentityProviderClient({
        region: 'us-east-1',
      });

      if (email && password) {
        try {
          const challengeResponse = await cognitoIdentityProviderClient.send(
            new RespondToAuthChallengeCommand({
              ChallengeName: 'NEW_PASSWORD_REQUIRED',
              ClientId: VITE_COGNITO_CLIENT_ID,
              ChallengeResponses: {
                USERNAME: email,
                NEW_PASSWORD: password,
              },
              Session: session, // Replace this with the actual session string you received
            })
          );

          if (
            challengeResponse.AuthenticationResult &&
            challengeResponse.AuthenticationResult.RefreshToken
          ) {
            const refreshToken: string = challengeResponse.AuthenticationResult.RefreshToken;
            try {
              const data = new URLSearchParams();
              data.append('grant_type', 'refresh_token');
              data.append('client_id', VITE_COGNITO_CLIENT_ID);
              data.append('refresh_token', refreshToken);
              const response = await this.axiosCognito.post('/oauth2/token', data);
              const accessToken = response.data.id_token;
              resolve({ accessToken });
            } catch (err) {
              console.error('[Auth Api]: ', err);
              reject(new Error('Internal server error'));
            }
          } else {
            // If there's no authentication result, handle accordingly, perhaps as an incomplete challenge process
            console.log(
              'Challenge process incomplete or another challenge required',
              challengeResponse
            );
            reject(new Error('Challenge process incomplete or another challenge required'));
          }
        } catch (error) {
          console.error('Error responding to auth challenge:', error);
          reject(error);
        }
      } else {
        reject(new Error('Email or password not provided'));
      }
    });
  }

  me(request: MeRequest): MeResponse {
    const { accessToken } = request;

    return new Promise((resolve, reject) => {
      try {
        const decodedToken = decode(accessToken) as any;
        resolve({
          email: decodedToken.email,
          org_id: decodedToken.org_id,
          smb_id: decodedToken.smb_id,
          role: decodedToken.role,
          exp: decodedToken.exp,
          businessType: decodedToken.business_type,
          id: decodedToken['cognito:username'],
        });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const authApi = new AuthApi();
