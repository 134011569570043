import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Notification } from 'src/types/notifications';

type LoadNotificationsAction = PayloadAction<Notification[]>;

interface State {
  notifications: Notification[];
}

const initialState: State = {
  notifications: [],
};

const reducers = {
  loadNotifications(state: State, action: LoadNotificationsAction): void {
    state.notifications = action.payload;
  },
};

export const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers,
});

export const { reducer } = slice;
