import { useEffect } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { notificationsAPI } from 'src/api/notifications';
import { slice } from 'src/slices/notifications';
import { useDispatch } from 'src/store';

export const useNotifications = () => {
  const dispatch = useDispatch()
  const fetchNotifications = (_: QueryFunctionContext<string[], unknown>) => {
    return notificationsAPI.getNotifications();
  };

  const { error, isLoading, data } = useQuery({
    queryKey: ['getNotifications'],
    refetchOnWindowFocus: false,
    queryFn: fetchNotifications,
  });

  useEffect(() => {
    dispatch(slice.actions.loadNotifications(data?.results ?? []));
  }, [data?.results, dispatch])

  return { error, isLoading, data };
};
