import { useEffect, useState } from 'react';
import { wait } from 'src/utils/wait';

export const useTypeWritingEffect = (response: string | null, speed = 10, waitToType = 0) => {
  const [typedResponse, setTypedResponse] = useState('');
  const [nextResponse, setNextResponse] = useState('');

  useEffect(() => {
    if (nextResponse && typedResponse.length < nextResponse.length) {
      const timer = setTimeout(() => {
        setTypedResponse((prev) => nextResponse.substr(0, prev.length + 1));
      }, speed);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typedResponse, nextResponse]);

  useEffect(() => {
    const t = async () => {
        await wait(waitToType);
        if (response !== nextResponse) {
          setNextResponse(response!);
          setTypedResponse('');
        }
    }
    t();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const triggerTyping = () => {
    setTypedResponse('');
  };

  return { typedResponse, triggerTyping };
};
