import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import { Layout as ChamberLayout } from 'src/layouts/v2-chambers';
import { AuthChamberGuard } from 'src/guards/user-role-permissions';

const Index = lazy(() => import('src/pages/v2-chambers/index'));
const ChatPage = lazy(() => import('src/pages/v2-chambers/chat'));
const MemoryPage = lazy(() => import('src/pages/v2-chambers/memory'));
const BusinessesPage = lazy(() => import('src/pages/v2-chambers/business'));
const InsightsPage = lazy(() => import('src/pages/v2-chambers/insights'));
const SkillsPage = lazy(() => import('src/pages/v2-chambers/skills'));
const SettingsPage = lazy(() => import('src/pages/v2-chambers/settings'));
const DemandsPage = lazy(() => import('src/pages/v2-chambers/demands'));
const DemandStatusPage = lazy(() => import('src/pages/v2-chambers/demands/demand-status'));
const BusinessProfiePage = lazy(() => import('src/pages/v2-chambers/business/profile'));

export const V2ChambersRoutes: RouteObject[] = [
  {
    path: 'workspace',
    element: (
      <AuthChamberGuard>
        <ChamberLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </ChamberLayout>
      </AuthChamberGuard>
    ),
    children: [
      {
        index: true,
        element: <Index />,
      },
      {
        path: 'chat',
        element: <ChatPage />,
      },
      {
        path: 'memory',
        children: [
          {
            index: true,
            element: <MemoryPage />,
          },
          {
            path: 'members',
            children: [
              {
                index: true,
                element: <BusinessesPage />,
              },
              {
                path: ':id',
                element: <BusinessProfiePage />
              },
            ],
            
          },
        ],
      },
      {
        path: 'insights',
        element: <InsightsPage />,
      },
      {
        path: 'skills',
        children: [
          {
            index: true,
            element: <SkillsPage />,
          },
          {
            path: 'demands',
            element: <DemandsPage />,
          },
          {
            path: 'demands/:id',
            element: <DemandStatusPage />,
          },
        ],
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
    ],
  },
];
