export const enableDevTools = import.meta.env.VITE_ENABLE_REDUX_DEV_TOOLS === 'true';
export const VITE_ALLOW_MULTI_CHAT = import.meta.env.VITE_ALLOW_MULTI_CHAT === 'true';
export const VITE_ALLOW_PROFILE_AND_NOTIFICATIONS =
  import.meta.env.VITE_ALLOW_PROFILE_AND_NOTIFICATIONS === 'true';
export const VITE_EMAIL_BASE_URL = import.meta.env.VITE_EMAIL_BASE_URL;
export const IS_DEV_MODE = import.meta.env.DEV;
export const VITE_WSS_CHAT_ENDPOINTL = import.meta.env.VITE_WSS_CHAT_ENDPOINTL;
export const VITE_ENABLE_SPEECH_FEATURE = import.meta.env.VITE_ENABLE_SPEECH_FEATURE === 'true';
export const VITE_API_LEVELPLANE = import.meta.env.VITE_API_LEVELPLANE;
export const VITE_COGNITO_API = import.meta.env.VITE_COGNITO_API;
export const VITE_COGNITO_GRANT_TYPE = import.meta.env.VITE_COGNITO_GRANT_TYPE;
export const VITE_COGNITO_CLIENT_ID = import.meta.env.VITE_COGNITO_CLIENT_ID;
export const VITE_COGNITO_REDIRECT_URL = import.meta.env.VITE_COGNITO_REDIRECT_URL;
export const VITE_WSS_LEVELPLANE = import.meta.env.VITE_WSS_LEVELPLANE;
export const VITE_INTERVIEW_FEATURE = import.meta.env.VITE_INTERVIEW_FEATURE === 'true';
export const VITE_OMNI_CHAT_FEATURE = import.meta.env.VITE_OMNI_CHAT_FEATURE === 'true';
export const CADELEC_INDEX_EVENT_ID = import.meta.env.VITE_CADELEC_INDEX_EVENT_ID;

export const KEYWORD_EMAIL_QUESTION = '<:ask_for_user_email:>';
export const LLM_CHAT_LKEYWORDS = ['<:start_message:>', '<:end_message:>', KEYWORD_EMAIL_QUESTION];
export const gtmConfig = {
  containerId: import.meta.env.VITE_GTM_CONTAINER_ID,
};
export const VITE_SIGN_IN = import.meta.env.VITE_SIGN_IN;
export const VITE_BASE_UI_URL = import.meta.env.VITE_BASE_UI_URL;

export const INDEX_CADELEC_ORGS = [
  '2e2a02d9-f376-4013-bcf4-2549eb30504f', // Prod
  // 'c58dbf6f-32aa-4b79-bffa-78aa33ca7447', // Dev
];
export const FIXED_BY_DAY = '2024-09-11';
export const TEST_USER_ID_V2_MEMBER = '0100be21-11db-4b5c-a252-9d54fbb2aa9f';
export const TEST_USER_ID_V2_SELLER = '2125fbcc-4f6a-4fcf-9310-40d35ccd8ccd';
export const SMALL_TILE_HEIGHT = '150px';
export const LARGE_TILE_HEIGHT = '280px';
