import {
  SMBBusiness,
  Insights,
  InsightsTilesBack,
  SMBInsights,
  Commodity,
  DashboardEvent,
  Meeting,
} from 'src/types/organization';
import { APIResultListDjango, APIResultListFast } from 'src/types/api';
import AuthAxios from '../auth-axios';
import { CADELEC_INDEX_EVENT_ID, VITE_API_LEVELPLANE } from 'src/config';
import { EnrichmentPercentage } from 'src/types/smb';
import { SMBForm } from 'src/types/upload-smbs';
import { format, set } from 'date-fns';
import {
  Attendee,
  AttendeeAgenda,
  AttendeeType,
  DownloadButtonOption,
  PaymentStatus,
} from 'src/types/event-planner';
import { ProfileMember } from 'src/types/chamber-members';

interface AttendeesQueryParams {
  smb_id: string;
  user_id: string;
  attendee_type: AttendeeType;
  payment_status: PaymentStatus;
  needs_commodities_of_smb: string; // supplier_smb_id
}

interface GetEventMeetings {
  owner_user_id: string;
  owner_smb_id: string;
  from_time: string;
  to_time: string;
}

interface PostMembership {
  org: string;
  member_since: number;
  is_active: boolean;
  membership_status: string;
  pilot_member: boolean;
  member_type: string;
}

class SMBApi extends AuthAxios {
  constructor() {
    super({ baseUrl: VITE_API_LEVELPLANE });
  }

  async getSMBsByOrganizaion(
    organizationId: string,
    query: undefined | string = undefined,
    offset: number,
    business_type?: string
  ): Promise<APIResultListDjango<SMBBusiness>> {
    try {
      const params: Record<string, unknown> = {
        org_id: organizationId,
        search_string: query ?? undefined,
        offset,
      };
      if (business_type) {
        params.business_type = business_type;
      }
      const { data } = await this.axiosInstance.get(`/smbs/list`, {
        params,
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  async getSMBDetails(smbId: string): Promise<SMBBusiness> {
    try {
      const { data } = await this.axiosInstance.get(`/smbs/${smbId}/`);
      return data;
    } catch (error) {
      return error;
    }
  }

  async getSMBProfiles(smbId: string): Promise<APIResultListFast<ProfileMember>> {
    try {
      const { data } = await this.axiosInstance.get(`/profiles`, {
        params: {
          smb_id: smbId,
        },
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  async createSMB(org_id: string, smbForm: SMBForm) {
    try {
      await this.axiosInstance.post(`/smbs/`, {
        org_id,
        name: smbForm.company,
        emails: [smbForm.email],
        location: {
          address: smbForm.address,
        },
        websites: [smbForm.website],
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateSMB(smbId: string, payload: Partial<SMBBusiness>): Promise<SMBBusiness> {
    try {
      const { data } = await this.axiosInstance.patch(`/smbs/${smbId}/`, payload);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getReport(smbId: string) {
    try {
      const { data } = await this.axiosInstance.get(`/smbs/${smbId}/download-report`, {
        responseType: 'blob',
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getSMBsCount(orgId: string) {
    try {
      const { data } = await this.axiosInstance.get(`/smbs/members`, {
        params: {
          org_id: orgId,
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async addChamberNote(note: string, smb_id: string, org: string) {
    try {
      await this.axiosInstance.post(`/smbs/${smb_id}/chamber-notes/`, {
        note,
        smb: smb_id,
        org,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getInsightTiles(orgId: string): Promise<Insights> {
    try {
      const { data } = await this.axiosInstance.get(`/smbs/insight-tiles`, {
        params: { org_id: orgId },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getInsightTilesBack(orgId: string): Promise<InsightsTilesBack> {
    try {
      const { data } = await this.axiosInstance.get(`/smbs/insight-tiles-back`, {
        params: { org_id: orgId },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getEnrichmentPercentage(orgId: string): Promise<EnrichmentPercentage> {
    try {
      const { data } = await this.axiosInstance.get(`/smbs/enrichment-percentage`, {
        params: { org_id: orgId },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getStorefrontStatus(id: string) {
    try {
      const { data } = await this.axiosInstance.get(`/storefrontconfig/${id}`);
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async changeStorefrontStatus(id: string) {
    try {
      await this.axiosInstance.patch(`/storefrontconfig/${id}`, {
        storefront_status: 'online',
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getMemberTiles(smbId: string): Promise<SMBInsights> {
    try {
      const { data } = await this.axiosInstance.get(`/smbs/member-tiles`, {
        params: { smb_id: smbId },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async fetchEvent(smbId: string): Promise<DashboardEvent> {
    try {
      const { data } = await this.axiosInstance.get(`/events/${CADELEC_INDEX_EVENT_ID}`);
      const dashboardEvent: DashboardEvent = {
        title: data.title,
        description: data.description,
        time_zone: data.time_zone,
        location: data.location,
        payment_required: data.payment_required,
        payment_info: data.payment_info,
        start_date: data.start_date,
        end_date: data.end_date,
        start_time: data.start_time,
        end_time: data.end_time,
        org_id: data.org_id,
        meeting_blocks: data.meeting_blocks,
      };
      return dashboardEvent;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getCommodities(config?: {
    limit: number;
    offset: number;
  }): Promise<APIResultListDjango<Commodity>> {
    try {
      const { data } = await this.axiosInstance.get(`/smbs/commodities`, {
        params: {
          limit: config?.limit ?? 50,
          offset: config?.offset ?? 0,
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async bookBreak(meetingId: string) {
    try {
      await this.axiosInstance.patch(`/events/${CADELEC_INDEX_EVENT_ID}/meetings/${meetingId}`, {
        description_template: 'Descanso',
        visibility: 'owner_private',
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async freeSlot(meetingId: string) {
    try {
      await this.axiosInstance.patch(`/events/${CADELEC_INDEX_EVENT_ID}/meetings/${meetingId}`, {
        visibility: 'public',
        description_template: 'Disponible',
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getAgendaTimeframe() {
    try {
      const { data } = await this.axiosInstance.get(
        `/events/${CADELEC_INDEX_EVENT_ID}/meeting_blocks`
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getCalendar(userId: string): Promise<APIResultListDjango<Meeting>> {
    try {
      const { data } = await this.axiosInstance.get(
        `/events/${CADELEC_INDEX_EVENT_ID}/attendees/${userId}/meetings`
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async enrollUser(eventId: string, userId: string, isEnterprise = false): Promise<void> {
    try {
      const currentYear = new Date().getFullYear();
      const startDate = format(
        set(new Date(), { month: 8, date: 11, year: currentYear }),
        'yyyy-MM-dd'
      );
      const endDate = format(
        set(new Date(), { month: 8, date: 11, year: currentYear }),
        'yyyy-MM-dd'
      );

      await this.axiosInstance.post(`/events/${eventId}/attendees`, {
        create_meetings: isEnterprise,
        user_id: userId,
        ...(isEnterprise
          ? {
              initial_meetings_details: {
                duration: 15,
                description: 'Disponible',
              },
              meeting_blocks: [
                {
                  start_date: startDate,
                  end_date: endDate,
                  start_time: '11:00:00',
                  end_time: '17:00:00',
                },
              ],
            }
          : {}),
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAttendeeByUserId(eventId: string, userId: string): Promise<Attendee | null> {
    try {
      const { data } = await this.axiosInstance.get(`/events/${eventId}/attendees/${userId}`);
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getAttendees(
    eventId: string,
    queryBy?: Partial<AttendeesQueryParams>
  ): Promise<APIResultListDjango<Attendee>> {
    try {
      const queryParams: Partial<AttendeesQueryParams> = {};
      if (queryBy?.attendee_type) {
        queryParams.attendee_type = queryBy.attendee_type;
      }
      if (queryBy?.payment_status) {
        queryParams.payment_status = queryBy.payment_status;
      }
      if (queryBy?.smb_id) {
        queryParams.smb_id = queryBy.smb_id;
      }
      if (queryBy?.user_id) {
        queryParams.user_id = queryBy.user_id;
      }
      if (queryBy?.needs_commodities_of_smb) {
        queryParams.needs_commodities_of_smb = queryBy.needs_commodities_of_smb;
      }
      const { data } = await this.axiosInstance.get(`/events/${eventId}/attendees`, {
        params: queryParams,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getEventMeetings(
    eventId: string,
    queryParams: Partial<GetEventMeetings>
  ): Promise<APIResultListDjango<Meeting>> {
    try {
      const params: Partial<Record<keyof GetEventMeetings, unknown>> = {};
      if (queryParams.owner_user_id) {
        params.owner_user_id = queryParams.owner_user_id;
      }
      if (queryParams.from_time) {
        params.from_time = queryParams.from_time;
      }
      if (queryParams.to_time) {
        params.to_time = queryParams.to_time;
      }
      if (queryParams.owner_smb_id) {
        params.owner_smb_id = queryParams.owner_smb_id;
      }

      const { data } = await this.axiosInstance.get(`/events/${eventId}/meetings`, {
        params: queryParams,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async downloadAgenda(
    eventId: string,
    userId: string,
    downloadOption: DownloadButtonOption
  ): Promise<any> {
    let headerAccept: string;
    if (downloadOption === 'excel') {
      headerAccept = 'application/vnd.ms-excel';
    } else if (downloadOption === 'pdf') {
      headerAccept = 'application/pdf';
    } else if (downloadOption === 'calendar') {
      headerAccept = 'text/calendar';
    } else {
      throw new Error('Download option is not supported');
    }

    try {
      const { data } = await this.axiosInstance.get(
        `/events/${eventId}/attendees/${userId}/meetings`,
        {
          headers: {
            Accept: headerAccept,
          },
          responseType: 'blob',
        }
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getMeetingsByUserId(
    eventId: string,
    userId: string
  ): Promise<APIResultListDjango<Meeting>> {
    try {
      const { data } = await this.axiosInstance.get(`/events/${eventId}/meetings`, {
        params: {
          owner_user_id: userId,
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateAttendee(
    eventId: string,
    userId: string,
    partialAttendee: Partial<Attendee>
  ): Promise<Attendee> {
    try {
      const patchRequest: Partial<Attendee> = {};
      if (partialAttendee.payment_status) {
        patchRequest.payment_status = partialAttendee.payment_status;
      }
      if (partialAttendee.meeting_blocks) {
        patchRequest.meeting_blocks = partialAttendee.meeting_blocks;
      }
      if (partialAttendee.notes) {
        patchRequest.notes = partialAttendee.notes;
      }
      if (partialAttendee.commodities) {
        patchRequest.commodities = partialAttendee.commodities;
      }
      const { data } = await this.axiosInstance.patch(
        `/events/${eventId}/attendees/${userId}`,
        patchRequest
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateMeetingBlock(
    eventId: string,
    userId: string,
    time: { startTime: string; endTime: string }
  ): Promise<boolean> {
    try {
      const currentYear = new Date().getFullYear();
      const startDate = format(
        set(new Date(), { month: 8, date: 11, year: currentYear }),
        'yyyy-MM-dd'
      );
      const endDate = format(
        set(new Date(), { month: 8, date: 11, year: currentYear }),
        'yyyy-MM-dd'
      );

      const { data } = await this.axiosInstance.patch(`/events/${eventId}/attendees/${userId}`, {
        meeting_blocks: [
          {
            start_date: startDate,
            end_date: endDate,
            start_time: time.startTime,
            end_time: time.endTime,
          },
        ],
      });
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateMembrship(smbId: string, payload: Partial<PostMembership>): Promise<APIResultListDjango<Meeting>> {
    try {
      const { data } = await this.axiosInstance.patch(`/smbs/${smbId}/membership`, payload);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export const smbApi = new SMBApi();
