import { type Locale, format, parseISO, parse } from 'date-fns';
import locale from 'date-fns/locale/en-US';

const formatDistanceLocale: Record<string, string> = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
};

export const customLocale: Locale = {
  ...locale,
  formatDistance: (token, count, options) => {
    options = options || {};

    const result = formatDistanceLocale[token].replace('{{count}}', count);

    if (options.addSuffix) {
      if (options.comparison > 0) {
        return 'in ' + result;
      } else {
        return result + ' ago';
      }
    }

    return result;
  },
};

export const timestampUnixToHourMinutes = (timestamp: number) => {
  return format(new Date(timestamp), 'h:mm aaaa');
};

export const timestampUnixToDate = (timestamp: number): string => {
  const timestampString = timestamp.toString();
  const isSeconds = timestampString.length === 10;
  const date = new Date(isSeconds ? timestamp * 1000 : timestamp);
  return format(date, 'yyyy/MM/dd HH:mm');
};

export const humanRedableDateString = (dateString: string) => {
  if (!dateString) {
    return '';
  }

  const parsedDate = parseISO(dateString);

  // Format the parsed date to a human-readable format
  return format(parsedDate, 'MMMM d, yyyy');
};
export const humanRedableTimeString = (timeString: string) => {
  if (!timeString) {
    return '';
  }
  // Parse the time string to a Date object
  const parsedTime = parse(timeString, 'HH:mm:ss', new Date());
  // Format the parsed time to "HH:mm"
  return format(parsedTime, 'HH:mm');
};
